
import './App.css';
import {useEffect} from 'react'
function App() {
  useEffect(() => {
    document.title = "My website"
  }, [])
  
  return (
    <div className="App">
      {/* public class Main {
        static void main(String[] args){
           
        }  
} */}
   <p>HELLO 👋 </p>
   <p>this is a placeholder i made! , still working on the real deal 😎 </p>

<div className='class'>
   <p> <span className='red'>public</span>  class Programmer {"{"}</p>
   <p> <span className='red'>private</span> static String <span className='blueish'>name</span> = <span className='green'>"EL ARIF AMINE"</span> ;</p>
   <p>  <span className='red'>private</span> static int <span className='blueish'>age</span> = <span className='blue'>21</span>;</p>
   <p>  <span className='red'>private</span> static String <span className='blueish'>country</span> = <span className='green'>"Morocco"</span>;</p>
   <p>  <span className='red'>private</span> static List{"<String>"} <span className='blueish'>languages</span> = [<span className='green'>JavaScript"</span>", <span className='green'>"Java"</span>, <span className='green'>"Python"</span>,  <span className='green'>"PHP"</span>,<span className='green'>"HTML"</span> ,<span className='green'>"CSS"</span> ,</p>
   <p><span className='green'>"SQL"</span> , <span className='green'>"C"</span>];</p>
   <p>  <span className='red'>private</span> static List{"<String>"} <span className='blueish'>frameworks</span> = [<span className='green'>"Spring"</span>,<span className='green'>"React"</span>,<span className='green'>"Express"</span>];</p>
   <p> static void main(String[] args){"{"}</p>
   <a href='https://twitter.com/Fox_boy4'>      System.out.println(<span className='green'>"Hit me up ! @Fox_boy4"</span>);</a>
           
   <p>   {"}"}  </p>
   <p>  {"}"}</p>

   </div>
    </div>
  );
}

export default App;
